<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>스페셜 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span>스페셜 관리</span>
          <span class="color-black">{{ articleUuid ? item.title : '등록' }}</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>제목<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.title"/>
              </td>
            </tr>
            <tr>
              <th>부제목<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.subTitle"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">프리뷰 이미지<em class="required">*</em></th>
              <td>
                <div>
                  <p style="margin-bottom: 5px;">스페셜 전체 리스트에서 보여질 이미지를 선택해주세요.</p>
                </div>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="preview_image">
                            <span>{{ item.previewImageUrl }}</span>
                          </label>
                          <input type="file" id="preview_image" @change="selectPreviewImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.previewImageUrl" :src="item.previewImageUrl" style="width: 200px; height: 200px;" alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top; padding-top: 50px;">메인 이미지</th>
              <td style="padding-top: 50px;">
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="main_image">
                            <span>{{ item.mainImageUrl }}</span>
                          </label>
                          <input type="file" id="main_image" @change="selectMainImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.mainImageUrl" :src="item.mainImageUrl" style="width: 200px; height: 200px;" alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <th>메인 내용</th>
              <td>
                <textarea v-model="item.mainContent"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">Writer 이미지</th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="writer_image">
                            <span>{{ item.writerImageUrl }}</span>
                          </label>
                          <input type="file" id="writer_image" @change="selectWriterImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.writerImageUrl" :src="item.writerImageUrl" style="width: 200px; height: 200px;" alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <th>Writer</th>
              <td>
                <input type="text" v-model="item.writer"/>
              </td>
            </tr>
            <tr>
              <th>Write 메시지</th>
              <td>
                <textarea v-model="item.writerMessage"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">세부 컨텐츠</th>
              <td>
                <!-- 내용 반복 렌더링 -->
                <div
                    v-for="(contentItem, index) in item.contents"
                    :key="index"
                    style="margin-bottom: 20px; border: 1px solid #e0e0e0; padding: 10px;"
                >
                  <!-- 이미지 업로드 -->
                  <div class="file-list">
                    <label>이미지</label>
                    <ul>
                      <li>
                        <div class="attachment">
                          <div class="filebox2">
                            <!-- 각 인덱스별 고유 id -->
                            <label :for="'contents_image_' + index">
                              <span>{{ contentItem.imageUrl }}</span>
                            </label>
                            <input
                                type="file"
                                :id="'contents_image_' + index"
                                @change="selectContentsImage($event, index)"
                                accept="image/*"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- 미리보기 -->
                    <img
                        v-if="contentItem.imageUrl"
                        :src="contentItem.imageUrl"
                        style="width: 200px; height: 200px; display: block; margin: 10px 0;"
                        alt=""
                    />
                  </div>

                  <!-- 제목 입력 -->
                  <div style="margin-top: 10px;">
                    <label>제목</label>
                    <input type="text" v-model="contentItem.title" />
                  </div>

                  <!-- 내용 입력 -->
                  <div style="margin-top: 10px;">
                    <label>내용</label>
                    <textarea v-model="contentItem.content"></textarea>
                  </div>

                  <!-- 삭제 버튼 -->
                  <button
                      type="button"
                      @click="removeContents(index)"
                      class="button red-button small"
                      style="margin-top: 10px;"
                  >
                    삭제
                  </button>
                </div>

                <button
                    type="button"
                    @click="addContents"
                    class="button blue-button small"
                >
                  세부 컨텐츠 추가
                </button>
              </td>
            </tr>
            <tr>
              <th>공개여부<em class="required">*</em></th>
              <td>
                <select v-model="item.isHidden">
                  <option value="false">공개</option>
                  <option value="true">비공개</option>
                </select>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>생성자</th>
              <td>
                <input type="text" :value="item.createdBy" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>생성일시</th>
              <td>
                <input type="text" :value="item.createdAt" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>최종 수정자</th>
              <td>
                <input type="text" :value="item.updatedBy" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>최종 수정일시</th>
              <td>
                <input type="text" :value="item.updatedAt" disabled/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'Special'}" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button @click="saveItem" type="button" class="button navy-button">{{articleUuid ? '수정하기' : '등록하기'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
const articleUuid = ref(route.params.uuid);

const item = ref({
  uuid: null,
  title: null,
  subTitle: null,
  previewImageUrl: null,
  mainContent: null,
  mainImageUrl: null,
  writer: null,
  writerMessage: null,
  writerImageUrl: null,
  isHidden: false,
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  updatedBy: null,
  contents: [],
})

const selectedPreviewImage = ref(null);
const selectedMainImage = ref(null);
const selectedWriterImage = ref(null);
const API_URL = "/contents";

const fetchItem = async () => {
  try {
    const response = await apiClient.get(API_URL + `/articles/specials/${articleUuid.value}`);
    item.value = response.data;
  } catch (error) {
    alert(error.message);
  }
};

const selectPreviewImage = (event) => {
  selectedPreviewImage.value = event.target.files[0];
  item.value.previewImageUrl = URL.createObjectURL(event.target.files[0]);
}

const selectMainImage = (event) => {
  selectedMainImage.value = event.target.files[0];
  item.value.mainImageUrl = URL.createObjectURL(event.target.files[0]);
}

const selectWriterImage = (event) => {
  selectedWriterImage.value = event.target.files[0];
  item.value.writerImageUrl = URL.createObjectURL(event.target.files[0]);
}

const addContents = () => {
  item.value.contents.push({
    imageUrl: null,
    title: null,
    content: null,
  });
};

const removeContents = (index) => {
  if (confirm("컨텐츠를 삭제 하시겠습니까?")) {
    item.value.contents.splice(index, 1);
  }
};

const selectContentsImage = (event, index) => {
  const file = event.target.files[0];
  if (file) {
    // 미리보기 URL 설정
    item.value.contents[index].imageUrl = URL.createObjectURL(file);

    // 업로드할 File 객체를 함께 저장 (새로운 프로퍼티 file)
    item.value.contents[index].file = file;
  }
};

const saveItem = async () => {
  if (!validateItem()) {
    return;
  }

  const msg = articleUuid.value ? "수정 하시겠습니까?" : "등록 하시겠습니까?";

  if (confirm(msg)) {
    const formData = new FormData();
    if (selectedPreviewImage.value) {
      formData.append('previewImage', selectedPreviewImage.value);
    }

    if (selectedMainImage.value) {
      formData.append('mainImage', selectedMainImage.value);
    }

    if (selectedWriterImage.value) {
      formData.append('writerImage', selectedWriterImage.value);
    }

    item.value.contents.forEach((content) => {
      if (content.file) {
        formData.append('contentsImages', content.file);
      } else {
        // 파일 없음 표기를 위해 빈 Blob 전송
        const emptyBlob = new Blob([], { type: 'application/octet-stream' });
        formData.append('contentsImages', emptyBlob);
      }
    });

    formData.append('reqDto', JSON.stringify(item.value));

    try {
      if (!articleUuid.value) {
        // 등록
        await apiClient.post(API_URL + `/articles/specials`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        // 수정
        await apiClient.put(API_URL + `/articles/specials/${articleUuid.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      const resultMsg = articleUuid.value ? "수정 되었습니다." : "등록 되었습니다.";
      alert(resultMsg);
      if (articleUuid.value) {
        await fetchItem();
      } else {
        history.back();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error.response.data);
    }
  }
};

const validateItem = () => {
  if (!item.value.title) {
    alert('제목은 필수입니다.');
    return false;
  }

  if (!item.value.subTitle) {
    alert('부제목은 필수입니다.');
    return false;
  }

  if (!item.value.previewImageUrl) {
    alert('프리뷰 이미지는 필수입니다.');
    return false;
  }

  if (item.value.isHidden === null) {
    alert('공개여부는 필수입니다.');
    return false;
  }

  return true;
}

onMounted(() => {
  if (articleUuid.value) {
    fetchItem();
  }
});
</script>
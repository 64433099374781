<template>
  <div class="contents">
    <div class="layout">

      <UserListHeader title="아티스트 승인 관리" menu="아티스트 승인 관리" />

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>검색어</th>
                <td>
                  <div class="input-search">
                    <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input"
                      placeholder="검색어 입력">
                    <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img
                        src="@/assets/images/ico/ico_search.svg" alt="" /></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <!-- 컬럼 비율 설정 -->
              <colgroup>
                <col style="width: 5%;"> <!-- NO -->
                <col style="width: 10%;"> <!-- 이름 -->
                <col style="width: 15%;"> <!-- 승인요청일시 -->
                <col style="width: 10%;"> <!-- 처리결과 -->
                <col style="width: 15%;"> <!-- 처리일시 -->
                <col style="width: 10%;"> <!-- 처리자 ID -->
                <col style="width: 15%;"> <!-- 활동자료 -->
                <col style="width: 20%;"> <!-- 빈 컬럼 -->
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>이름</th>
                  <th>승인요청일시</th>
                  <th>처리결과</th>
                  <th>처리일시</th>
                  <th>처리자 ID</th>
                  <th>활동자료</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <router-link :to="{ name: 'UserApprovalDetail', params: { userUuid: item.userUuid } }">
                      {{ item.userName }}
                    </router-link>
                  </td>
                  <td>{{ item.reqAt }}</td>
                  <td>
                    {{
                      item.status === 'APPROVED'
                        ? '승인'
                        : item.status === 'REJECTED'
                          ? '반려'
                          : item.status === 'PENDING'
                            ? '보류'
                            : ''
                    }}
                  </td>
                  <td>{{ item.doneAt }}</td>
                  <td>{{ item.doneBy }}</td>
                  <td>
                    <button @click="downloadFiles(item.userUuid, item.userName)" type="button"
                      class="button green-button small">다운로드</button>
                  </td>
                  <td v-if="isEditing[index]" style="display: flex; gap: 10px; ">
                    <button @click="approval(item.userUuid, item.userName, 'APPROVED')" type="button"
                      class="button blue-button small">승인</button>
                    <button @click="approval(item.userUuid, item.userName, 'REJECTED')" type="button"
                      class="button red-button small">반려</button>
                    <button @click="approval(item.userUuid, item.userName, 'PENDING')" type="button"
                      class="button navy-button small">보류</button>
                  </td>
                  <td v-else>
                    <button @click="editMode(index, true)" type="button" class="button green-button small">변경</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserListHeader from "./UserListHeader.vue";
import { onMounted, ref } from 'vue';
import { apiClient } from "@/services/auth-header";
import { saveAs } from 'file-saver';
import JSZip from "jszip";

const items = ref([]);
const searchQuery = ref('');
const isEditing = ref([]); // 각 행의 편집 상태를 저장하는 배열

const fetchItems = async () => {
  await apiClient.get(`/users/approvals`)
    .then(response => {
      items.value = response.data ?? []; // 데이터를 확인하여 null일 경우 빈 배열 할당
      isEditing.value = items.value.map(item => item.status === null); // 상태에 따라 초기값 설정
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

const approval = async (userUuid, userName, approvalString) => {
  if (confirm(userName + " 사용자를 " + approvalString + " 하시겠습니까?")) {
    await apiClient.put(`/users/${userUuid}/approvals`, {
      status: approvalString,
    })
      .then(() => {
        alert(approvalString + " 되었습니다.")
      })
      .catch(error => {
        console.error('Error:', error);
      });
    await fetchItems();
  }
};

const search = () => {
  fetchItems();
};

const editMode = (index, value) => {
  isEditing.value[index] = value; // 해당 행을 편집 모드로 전환
};

const downloadFiles = async (userUuid, userName) => {
  await apiClient.get(`/users/${userUuid}/approvals/file-urls`)
    .then(response => {
      const fileUrls = response.data.urls ?? []; // 데이터를 확인하여 null일 경우 빈 배열 할당
      downloadAttachmentsAndCreateZip(fileUrls, userName);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

const getFileNameFromUrl = (presignedUrl) => {
  const encodedFileName = presignedUrl.split("/").pop()?.split("?")[0];
  return encodedFileName ? decodeURIComponent(encodedFileName) : null;
};

async function downloadAttachmentsAndCreateZip(urls, userName) {
  try {
    const zip = new JSZip();

    const fetchPromises = urls.map(url =>
      fetch(url).then(response =>
        response.blob().then(blob => ({
          blob,
          fileName: getFileNameFromUrl(url),
        })),
      ),
    );

    const files = await Promise.all(fetchPromises);

    files.forEach(({ blob, fileName }) => {
      if (fileName) {
        // 파일 추가
        zip.file(fileName, blob);
      }
    });

    // ZIP 파일 생성 및 다운로드
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, userName + ".zip");
    });
  } catch (error) {
    console.error("첨부파일 다운로드 중 오류 발생:", error);
  }
}

onMounted(async () => {
  await fetchItems();
});
</script>
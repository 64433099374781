import {createApp} from 'vue';
import App from './App.vue';
import pinia from './store';
import router from './router';
import '@/assets/css/style.css';
import $ from 'jquery';
import '@vuepic/vue-datepicker/dist/main.css';
import { mask } from 'vue-the-mask';

window.$ = $;
window.env = process.env;

const app = createApp(App);
app.use(router);
app.use(pinia);
app.directive('mask', mask);
app.mount('#app');

$('#goTop').click(function(){
    $('html,body').animate({ scrollTop: 0 }, 'fast');
});

$('input[name="search-input"]').focus(function(){
    $(this).parent().find('.keyword-del').show();
});

$('.keyword-del').click(function(){
    $(this).parent().find('input[name="search-input"]').val('');
    $(this).hide();
});

$('.toggle-aside').click(function(){
    $('.panel').toggleClass('on');
    $('aside').toggleClass('active');
});

$('table thead th #allchk').change(function(){
    if($(this).is(":checked")) {
        $( '#allchk').attr( 'checked', true );
        $( 'table tbody tr td input:checkbox').attr( 'checked', true );
        $('table tbody tr').addClass("checked");
    } else {
        $( '#allchk').attr( 'checked', false );
        $( 'table tbody tr td input:checkbox').attr( 'checked', false );
        $('table tbody tr').removeClass("checked");
    }
});

$('table tbody tr td input:checkbox').change(function(){
    if($(this).is(":checked")) {
        $( this ).attr( 'checked', true )
        $(this).parents('tr').addClass("checked");
    } else {
        $( this ).attr( 'checked', false )
        $(this).parents('tr').removeClass("checked");
    }
});

$('body').removeClass('on');
$('.panel').addClass('on');
$('aside').addClass('active');

$('.toggle-box2 dt').off("click").on('click', function() {
    function slideDown(target) {
        slideUp();
        $(target).addClass('on').next('dd').slideDown('fast');
    }
    function slideUp() {
        $('.toggle-box2 dt').removeClass('on').next('dd').slideUp('fast');
    }
    $(this).hasClass('on') ? slideUp() : slideDown(this);
});

<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>공지사항 관리</h2>
        <div class="breadcrumbs">
          <span>운영 관리</span>
          <span class="color-black">공지사항 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>검색어</th>
                <td>
                  <div class="input-search">
                    <input v-model="searchQuery" @keyup.enter="loadItem" type="text" name="search-input"
                      placeholder="검색어 입력">
                    <i @click="loadItem" class="button-search" style="cursor: pointer;"><img
                        src="@/assets/images/ico/ico_search.svg" alt="" /></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="deleteItems" type="button" class="delete-button">삭제</button>
            <button @click="addItem" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <colgroup>
                <col style="width:60px;">
                <col style="width:70px;">
                <col>
                <col style="width:120px;">
                <col style="width:120px;">
                <col style="width:120px;">
                <col style="width:120px;">
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span class="checkbox-type1">
                      <input @click="toggleAllCheck" type="checkbox" id="allchk">
                      <label for="allchk">체크</label>
                    </span>
                  </th>
                  <th>NO</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>등록일</th>
                  <th>상단 고정</th>
                  <th>숨김 여부</th>
                </tr>
              </thead>
              <tbody ref="tbodyRef">

                <tr v-for="(item, index) in items" :key="item.uuid">
                  <td>
                    <span class="checkbox-type1">
                      <input v-model="selectedItems" :id="item.uuid" type="checkbox" :value="item.uuid">
                      <label :for="item.uuid">체크</label>
                    </span>
                  </td>
                  <td>{{ index }}</td>
                  <td>
                    <router-link :to="{ name: 'NoticeDetail', params: { uuid: item.uuid } }">
                      {{ item.title }}
                    </router-link>
                  </td>
                  <td>{{ item.createdBy }}</td>
                  <td>{{ item.createdAt }}</td>
                  <td>{{ item.isPinned }}</td>
                  <td>{{ item.isHidden }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { apiClient } from "@/services/auth-header";
import router from "@/router";

const selectedItems = ref([]);
const searchQuery = ref('');
const items = ref([]);
const API_URL = "/support";

const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/notices`, {
      params: { title: searchQuery.value }
    });
    items.value = response.data;
  } catch (error) {
    console.error("Error fetching Notice data:", error);
  }
};

const deleteItems = async () => {
  if (selectedItems.value.length === 0) {
    alert("삭제할 항목을 선택하세요.");
    return;
  }
  if (confirm("삭제 하시겠습니까?")) {
    try {
      const response = await apiClient.delete(`${API_URL}/notices`, { data: selectedItems.value });
      if (response.status === 200) {
        alert("삭제되었습니다.")
        selectedItems.value = [];
        await loadItem();
      } else {
        alert("Error");
      }
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }
};

const addItem = () => {
  router.push("/support/notice/create")
};

const toggleAllCheck = (event) => {
  if (event.target.checked) {
    selectedItems.value = items.value.map(item => item.uuid);
  } else {
    selectedItems.value = [];
  }
};

onMounted(async () => {
  loadItem();
});

</script>

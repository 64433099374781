<style>
.interest-item {
  display: flex;
  flex-direction: column; /* 세로로 정렬 */
  align-items: flex-start; /* 텍스트와 체크박스를 왼쪽 정렬 */
}

.interest-item label {
  margin-top: 5px; /* 체크박스와 텍스트 사이 간격 */
}
</style>
<template>
  <div class="contents">
    <div class="layout">
      <UserListHeader title="일반 회원 상세 정보" menu="일반 회원 관리" :selected="item.name"/>

      <div class="white-panel mt30">
        <div v-if="item" class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>아이디<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.accountId" disabled/>
              </td>
            </tr>
            <tr>
              <th>이름<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.name" disabled/>
              </td>
            </tr>
            <tr>
              <th>연락처<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.phoneNum" disabled/>
              </td>
            </tr>
            <tr>
              <th>이메일<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.email" disabled/>
              </td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td colspan="5">
                <input type="text" v-model="item.birthDate" disabled/>
              </td>
            </tr>
            <tr>
              <th>성별</th>
              <td colspan="5">
                <input type="text" v-model="genderText" disabled/>
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="5">
                <input type="text" v-model="item.address" disabled/>
              </td>
            </tr>
            <tr>
              <th>상세주소</th>
              <td colspan="5">
                <input type="text" v-model="item.addressDetail" disabled/>
              </td>
            </tr>
            <tr>
              <th>관심분야</th>
              <td colspan="5">
                <div class="grid-layout">
                  <div v-for="(interest, index) in USER_INTERESTS" :key="index" class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" :id="'chk-ct' + index" :value="interest.value" v-model="item.userInterests" disabled />
                    <label :for="'chk-ct' + index">{{ interest.label }}</label>
                    <input v-if="interest.value === 'ETC' && item.userInterests.includes('ETC')" v-model="item.userInterestEtc" type="text" class="etc" style="width: 700px; margin-left: 15px;" disabled>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>정책동의</th>
              <td colspan="5">
                <div class="grid-layout">
                  <div class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" v-model="item.terms" id="terms" disabled/>
                    <label for="terms">(필수) 서비스 이용약관</label>
                  </div>
                  <div class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" v-model="item.privacyRequired" id="privacyRequired" disabled/>
                    <label for="privacyRequired">(필수) 개인정보 처리방침</label>
                  </div>
                  <div class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" v-model="item.privacyOptional" id="privacyOptional" disabled/>
                    <label for="privacyOptional">(선택) 개인정보 수집 및 이용</label>
                  </div>
                  <div class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" v-model="item.agreeMarketingSms" id="privacyOptional" disabled/>
                    <label for="privacyOptional">(선택) 정보/이벤트 SMS 수신에 동의합니다.</label>
                  </div>
                  <div class="grid-item" style="display: flex; align-items: center; margin: 5px;">
                    <input type="checkbox" v-model="item.agreeMarketingEmail" id="privacyOptional" disabled/>
                    <label for="privacyOptional">(선택) 정보/이벤트 메일 수신에 동의합니다.</label>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'UserMemberList' }" class="button white-button round-button gray2">목록</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserListHeader from "./UserListHeader.vue";
import {computed, onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import {getGenderText} from "@/common/enum-converter";
import {USER_INTERESTS} from "@/common/common-enum";

const route = useRoute();
const userUuid = ref(route.params.userUuid);

const item = ref({
  uuid: "",
  accountId: "",
  email: "",
  phoneNum: "",
  name: "",
  roleType: "",
  birthDate: "",
  genderType: "",
  address: "",
  addressDetail: "",
  zoneCode: "",
  buildingName: "",
  userInterests: [],
  userInterestEtc: "",
  terms: false,
  privacyRequired: false,
  privacyOptional: false,
  agreeBusinessEmail: false,
  agreeMarketingEmail: false,
  agreeBusinessSms: false,
  agreeMarketingSms: false,
});

const fetchItem = async () => {
  if (userUuid.value) {
    await apiClient.get(`/users/${userUuid.value}`)
      .then(response => {
        item.value = response.data;
      })
      .catch(error => {
        alert(error.response.data.message);
      });
  }
};

const genderText = computed(() => {
  return getGenderText(item.value.genderType);
});

onMounted(() => {
  fetchItem();
});
</script>

<style scoped>
input[type="text"]{
  background-color: #e5edff;
}
</style>
<template>
  <div class="contents">
    <div class="layout">
      <div class="page-top">
        <h2>공지사항 상세</h2>
        <div class="breadcrumbs">
          <span>공지사항 관리</span>
          <span class="color-black">공지사항 상세</span>
        </div>
      </div>
      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>제목<em class="required">*</em></th>
                <td>
                  <input v-model="notice.title" type="text" placeholder="제목을 입력하세요" />
                </td>
              </tr>
              <tr>
                <th>작성자</th>
                <td>
                  <span>{{ notice.createdBy }}</span>
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>
                  <span>{{ notice.createdAt }}</span>
                </td>
              </tr>
              <tr>
                <th>내용<em class="required">*</em></th>
                <td>
                  <textarea id="content" v-model="notice.content" placeholder="" />
                </td>
              </tr>
              <!-- 상단 고정 여부 -->
              <tr>
                <th>상단 고정</th>
                <td>
                  <label>
                    <input type="radio" v-model="notice.isPinned" :value="true" />
                    예
                  </label>
                  <label>
                    <input type="radio" v-model="notice.isPinned" :value="false" />
                    아니오
                  </label>
                </td>
              </tr>

              <!-- 숨김 여부 -->
              <tr>
                <th>숨김 여부</th>
                <td>
                  <label>
                    <input type="radio" v-model="notice.isHidden" :value="true" />
                    숨김
                  </label>
                  <label>
                    <input type="radio" v-model="notice.isHidden" :value="false" />
                    표시
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link to="/support/notice" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button v-if="noticeUuid" @click="updateItem" type="button" class="button navy-button">수정하기</button>
          <button v-else @click="saveItem" type="button" class="button navy-button">등록하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { apiClient } from "@/services/auth-header";
import { useRoute } from "vue-router";
import router from "@/router";

const route = useRoute();
const notice = ref({
  title: '',
  createdBy: '',
  createdAt: '',
  content: '',
  isPinned: false,
  isHidden: false,
});

// 라우터 파라미터로 공지사항 ID를 받음
const noticeUuid = ref(route.params.uuid);

const API_URL = "/support"

const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/notices/${noticeUuid.value}`);
    notice.value = response.data;
  } catch (error) {
    console.error("Error fetching NoticeDetail data:", error);
  }
};

const saveItem = async () => {
  try {
    let response = await apiClient.post(`${API_URL}/notices`, notice.value);
    alert(`등록 성공\n 제목: ${response.data.title}`);
    router.replace("/support/notice");
  } catch (error) {
    console.error("Error saving Notice:", error);
  }
};

const updateItem = async () => {
  try {
    let response = await apiClient.put(`${API_URL}/notices/${noticeUuid.value}`, notice.value);
    alert(`수정 성공\n 제목: ${response.data.title}`);
    router.replace("/support/notice");
  } catch (error) {
    console.error("Error saving Notice:", error);
  }
};

onMounted(() => {
  if (noticeUuid.value) {
    loadItem(); // 수정할 공지사항 데이터 로드
  }
});
</script>
<template>
  <div class="page-top">
    <h2>{{ title }}</h2>
    <div class="breadcrumbs">
      <span>회원 관리</span>
      <span :class="{ 'color-black': !selected }">{{ menu }}</span>
      <span :class="{ 'color-black': selected }">{{ selected }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserListHeader",
  props: {
    title: {
      type: String,
    },
    menu: {
      type: String,
    },
    selected: {
      type: String,
    }
  },
};
</script>
<template>
  <div class="contents">
    <div class="layout">
      <div class="page-top">
        <h2>공지사항 상세</h2>
        <div class="breadcrumbs">
          <span>공지사항 관리</span>
          <span class="color-black">공지사항 상세</span>
        </div>
      </div>
      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>분류</th>
                <td>
                  <span>{{ inquiry.type }}</span>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <span>{{ inquiry.title }}</span>
                </td>
              </tr>
              <tr>
                <th>작성자</th>
                <td>
                  <span>{{ inquiry.userAccountId }}</span>
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>
                  <span>{{ inquiry.createdAt }}</span>
                </td>
              </tr>
              <tr>
                <th>내용</th>
                <td>
                  <div v-if="inquiry.privatePresignedUrls && inquiry.privatePresignedUrls.length">
                    <div v-for="(url, index) in inquiry.privatePresignedUrls" :key="index">
                      <!-- URL로 이미지를 표시 -->
                      <img :src="url" alt="Image" class="inquiry-image" />
                    </div>
                  </div>
                  <span>{{ inquiry.content }}</span>
                </td>
              </tr>
              <tr><td colspan="2"><hr /></td></tr>
              <tr>
                <th>답변자</th>
                <td>
                  <span>{{ inquiry.answerAdminAccountId }}</span>
                </td>
              </tr>
              <tr>
                <th>답변일</th>
                <td>
                  <span>{{ inquiry.answeredAt }}</span>
                </td>
              </tr>
              <tr>
                <th>답변</th>
                <td>
                  <textarea id="answer" v-model="inquiry.answer" placeholder="" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link to="/support/inquiry" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button v-if="inquiryUuid" @click="updateItem" type="button" class="button navy-button">답변하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { apiClient } from "@/services/auth-header";
import { useRoute } from "vue-router";
import router from "@/router";
import { INQUIRY_TYPE } from "@/common/common-enum";

const route = useRoute();
const inquiry = ref({
  type: '',
  title: '',
  userAccountId: '',
  createdAt: '',
  isAnswered: '',
  privatePresignedUrls: [],
  content: '',
  answerAdminAccountId: '',
  answeredAt: '',
  answer: '',
});

// 라우터 파라미터로 공지사항 ID를 받음
const inquiryUuid = ref(route.params.uuid);

const API_URL = "/support"

const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/inquiries/${inquiryUuid.value}`);
    inquiry.value = response.data;
    inquiry.value.type = getLabelFromType(INQUIRY_TYPE, inquiry.value.type);
  } catch (error) {
    console.error("Error fetching inquiryDetail data:", error);
  }
};

const getLabelFromType = (typeList, value) => {
  const label = typeList.find(type => type.value === value)?.label;
  return label;
}

const updateItem = async () => {
  try {
    console.log("updata:",inquiry.value)
    let response = await apiClient.put(`${API_URL}/inquiries/${inquiryUuid.value}`, { answer: inquiry.value.answer });
    alert("답변 성공");
    router.replace("/support/inquiry");
  } catch (error) {
    console.error("Error saving inquiry:", error);
  }
};

onMounted(() => {
  if (inquiryUuid.value) {
    loadItem(); // 수정할 공지사항 데이터 로드
  }
});
</script>
<template>
  <aside v-if="menuList" class="active">
    <button type="button" class="toggle-aside"></button>
    <a href="#" class="link-dashboard">메인 대시보드</a>
    <dl class="toggle-box2">
      <template v-for="(menu, index) in menuList" :key="menu.id">
        <div class="menu-item">
          <input type="checkbox" :id="'menu-item-'+ index" class="menu-toggle" style="display: none;"/>
          <label :for="'menu-item-'+index" class="menu-label">
            <dt>
              {{ menu.name }}
            </dt>
          </label>
          <dd class="menu-content">
            <template v-if="menu.subMenu && menu.subMenu.length">
              <template v-for="(subMenu, subMenuIndex) in menu.subMenu" :key="subMenuIndex">
                <router-link :to="subMenu.url">
                  {{ subMenu.name }}
                </router-link>
              </template>
            </template>
          </dd>
        </div>
      </template>
    </dl>
  </aside>
</template>

<script setup>

const menuList = [
  {
    name: "컨텐츠 관리",
    subMenu: [
      { name: "배너 관리", url: "/content/banner" },
      { name: "작가 노출 관리", url: "/content/exposure" },
      { name: "스페셜 관리", url: "/article/special" },
      { name: "전시 관리", url: "/article/exhibitions" },
      { name: "이벤트 관리", url: "/article/events" },
      { name: "뉴스 관리", url: "/article/news" },
    ],
  },
  {
    name: "회원 관리",
    subMenu: [
      { name: "일반 회원 관리", url: "/user/member" },
      { name: "아티스트 관리", url: "/user/artist" },
      { name: "아티스트 승인 관리", url: "/user/approval" },
      { name: "관리자 계정 관리", url: "/user/admin" },
    ],
  },
  {
    name: "운영 관리",
    subMenu: [
      { name: "공지사항 관리", url: "/support/notice" },
      { name: "문의사항 관리", url: "/support/inquiry" },
    ],
  },
];

</script>
<style>
.menu-content {
  display: none;
}

.menu-toggle:checked + .menu-label + .menu-content {
  display: block;
}

.menu-label {
  cursor: pointer;
}
</style>
<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>스페셜 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">스페셜 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input" placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="deleteItems" type="button" class="delete-button">삭제</button>
            <button @click="addArticle" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <!-- 컬럼 비율 설정 -->
              <colgroup>
                <col style="width: 5%;"> <!-- 체크 -->
                <col style="width: 5%;"> <!-- NO -->
                <col style="width: 45%;"> <!-- 제목 -->
                <col style="width: 10%;"> <!-- Writer -->
                <col style="width: 10%;"> <!-- 공개여부 -->
                <col style="width: 10%;"> <!-- 작성자 -->
                <col style="width: 15%;"> <!-- 등록일 -->
              </colgroup>
              <thead>
              <tr>
                <th>
									<span class="checkbox-type1">
										<input @click="toggleAllCheck" type="checkbox" id="allchk">
										<label for="allchk">체크</label>
									</span>
                </th>
                <th>NO</th>
                <th>제목</th>
                <th>Writer</th>
                <th>공개여부</th>
                <th>작성자</th>
                <th>등록일</th>
              </tr>
              </thead>
              <tbody ref="tbodyRef">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                    <span class="checkbox-type1">
                        <input v-model="selectedItems" :id="item.uuid" type="checkbox" :value="item.uuid">
                        <label :for="item.uuid">체크</label>
                    </span>
                </td>
                <td>{{ index+1 }}</td>
                <td>
                  <router-link :to="{ name: 'SpecialDetail', params: { uuid: item.uuid } }"
                               style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 100%;">
                    {{ item.title }}
                  </router-link>
                </td>
                <td>{{ item.writer }}</td>
                <td>{{ !item.isHidden ? "공개" : "비공개" }}</td>
                <td>{{ item.createdBy }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import router from "@/router";
import {getGenderText} from "@/common/enum-converter";

const route = useRoute();
const items = ref([]);
const selectedItems = ref([]);
const searchQuery = ref('');
const articleType = ref(route.params.articleType);
const API_URL = "/contents";

const fetchItems = async () => {
  await apiClient.get(API_URL + '/articles/specials', {
    params: { q: searchQuery.value }
  })
  .then(response => {
    items.value = response.data;
  })
  .catch(error => {
    console.error('Error:', error);
  });
};

const deleteItems = async () => {
  if(selectedItems.value.length === 0){
    alert("삭제할 항목을 선택하세요.");
    return;
  }
  if(confirm("삭제 하시겠습니까?")){
    try {
      const response = await apiClient.delete(API_URL + '/articles/specials', { data: selectedItems.value });
      if (response.status === 200) {
        alert("삭제되었습니다.")
        selectedItems.value = [];
        await fetchItems();
      }
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }
};

const search = () => {
  fetchItems();
};

const addArticle = () => {
  router.push(`/content/article/special`);
};

const toggleAllCheck = (event) => {
  if (event.target.checked) {
    selectedItems.value = items.value.map(item => item.uuid);
  } else {
    selectedItems.value = [];
  }
};

onMounted(async () => {
  await fetchItems();
});

</script>

export const ARTIST_INTERESTS = [
  {value: 'PROMOTION', label: '아티스트 및 작품 홍보'},
  {value: 'ARCHIVING', label: '아카이빙 및 포트폴리오 기능 이용'},
  {value: 'SELLING', label: '직접 제작한 아트상품 판매'},
  {value: 'INFORMATION', label: '예술 관련 정보 확인'},
  {value: 'COMMUNITY', label: '커뮤니티 활동'},
  {value: 'COMMERCIAL_OPPORTUNITIES', label: '상업적 기회(협업 등)'},
  {value: 'ETC', label: '기타(직접 입력)'},
];

export const USER_INTERESTS = [
  {value: 'ARTIST_INFO', label: '아티스트 및 작품 정보'},
  {value: 'COMMUNITY', label: '커뮤니티 활동'},
  {value: 'ART_INFO', label: '예술 관련 정보'},
  {value: 'BUYING', label: '아티스트 상품 구매'},
  {value: 'ETC', label: '기타(직접 입력)'},
];

export const USER_ROLE = [
  {value: 'ARTIST', label: '아티스트'},
  {value: 'MEMBER', label: '일반'},
  {value: 'ARTIST_PENDING', label: '아티스트 승인 대기'},
];

export const ADMIN_USER_ROLE = [
  {value: 'SUPER', label: '최고권한'},
  {value: 'CONTENT', label: '컨텐츠 관리 권한'},
  {value: 'USER', label: '회원 관리 권한'},
];

export const ARTICLE_NEWS_TYPE = [
  {value: 'SUPPORT', label: '지원'},
  {value: 'COMPETITION', label: '공모'},
]

export const INQUIRY_TYPE = [
  {value: 'INQUIRY', label: '문의 사항'},
  {value: 'CONTENT_POST_REQUEST', label: '콘텐츠 게시 요청'},
  {value: 'USER_ROLE_TYPE_CHANGE', label: '회원 유형 전환'},
]

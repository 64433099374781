<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>문의사항 관리</h2>
        <div class="breadcrumbs">
          <span>운영 관리</span>
          <span class="color-black">문의사항 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>검색어</th>
                <td>
                  <div class="input-search">
                    <input v-model="searchQuery" @keyup.enter="loadItem" type="text" name="search-input"
                      placeholder="검색어 입력">
                    <i @click="loadItem" class="button-search" style="cursor: pointer;"><img
                        src="@/assets/images/ico/ico_search.svg" alt="" /></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <colgroup>
                <col style="width:70px;">
                <col style="width:120px;">
                <col>
                <col style="width:120px;">
                <col style="width:120px;">
                <col style="width:120px;">
                <col style="width:120px;">
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>분류</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>등록일</th>
                  <th>답변 여부</th>
                  <th>답변 작성일</th>
                </tr>
              </thead>
              <tbody ref="tbodyRef">
                <tr v-for="(item, index) in items" :key="item.uuid" :class="{ 'row-completed': item.isAnswered, 'row-pending': !item.isAnswered }">
                  <td>{{ index }}</td>
                  <td>{{ item.type }}</td>
                  <td>
                    <router-link :to="{ name: 'InquiryDetail', params: { uuid: item.uuid } }">
                      {{ item.title }}
                    </router-link>
                  </td>
                  <td>{{ item.userAccountId }}</td>
                  <td>{{ item.createdAt }}</td>
                  <td>{{ item.isAnswered }}</td>
                  <td>{{ item.answeredAt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { apiClient } from "@/services/auth-header";
import router from "@/router";
import { INQUIRY_TYPE } from "@/common/common-enum";

const selectedItems = ref([]);
const searchQuery = ref('');
const items = ref([]);
const API_URL = "/support";

const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/inquiries`, {
      params: { title: searchQuery.value }
    });
    items.value = response.data;
    
    items.value = items.value.map(item => {
      item.type = getLabelFromType(INQUIRY_TYPE, item.type);
      return item;
    });
  } catch (error) {
    console.error("Error fetching Notice data:", error);
  }
};

const getLabelFromType = (typeList, value) => {
  const label = typeList.find(type => type.value === value)?.label;
  return label;
}

const deleteItems = async () => {
  if (selectedItems.value.length === 0) {
    alert("삭제할 항목을 선택하세요.");
    return;
  }
  if (confirm("삭제 하시겠습니까?")) {
    try {
      const response = await apiClient.delete(`${API_URL}/notices`, { data: selectedItems.value });
      if (response.status === 200) {
        alert("삭제되었습니다.")
        selectedItems.value = [];
        await loadItem();
      } else {
        alert("Error");
      }
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }
};

const addItem = () => {
  router.push("/support/notice/create")
};

const toggleAllCheck = (event) => {
  if (event.target.checked) {
    selectedItems.value = items.value.map(item => item.uuid);
  } else {
    selectedItems.value = [];
  }
};

onMounted(async () => {
  loadItem();
});

</script>

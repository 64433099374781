import {createRouter, createWebHistory} from 'vue-router';
import {useMainStore} from "@/store/pinia";
import UserManagement from "@/views/user/UserManagement.vue";
import Home from "@/views/Home.vue";
import ArticleManagement from "@/views/contents/ArticleManagement.vue";
import ExposureManagement from "@/views/contents/ExposureManagement.vue";
import BannerManagement from "@/views/contents/BannerManagement.vue";
import BannerDetail from "@/views/contents/BannerDetail.vue";
import ArticleDetail from "@/views/contents/ArticleDetail.vue";
import UserDetail from "@/views/user/UserDetail.vue";
import MenuManagement from "@/views/system/MenuManagement.vue";
import UserApprovalList from "@/views/user/UserApprovalList.vue";
import UserApprovalDetail from "@/views/user/UserApprovalDetail.vue";
import UserMemberList from "@/views/user/UserMemberList.vue";
import UserMemberDetail from "@/views/user/UserMemberDetail.vue";
import NoticeDetail from '@/views/support/NoticeDetail.vue';
import NoticeManagement from '@/views/support/NoticeManagement.vue';
import InquiryManagement from '@/views/support/InquiryManagement.vue';
import InquiryDetail from '@/views/support/InquiryDetail.vue';
import SpecialManagement from "@/views/contents/SpecialManagement.vue";
import SpecialDetail from "@/views/contents/SpecialDetail.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/content/banner',
        name: 'Banner',
        component: BannerManagement,
    },
    {
        path: '/content/banner/:id',
        name: 'BannerDetail',
        component: BannerDetail,
        props: true
    },
    {
        path: '/content/exposure',
        name: 'Exposure',
        component: ExposureManagement,
        props: true
    },
    {
        path: '/article/special',
        name: 'Special',
        component: SpecialManagement,
    },
    {
        path: '/content/article/special/:uuid?',
        name: 'SpecialDetail',
        component: SpecialDetail,
        props: true
    },
    {
        path: '/article/:articleType',
        name: 'Article',
        component: ArticleManagement,
        props: true
    },
    {
        path: '/content/article/:articleType/:uuid?',
        name: 'ArticleDetail',
        component: ArticleDetail,
        props: true
    },
    {
        path: '/user/member',
        name: 'UserMemberList',
        component: UserMemberList,
        props: true
    },
    {
        path: '/user/:userUuid/member',
        name: 'UserMemberDetail',
        component: UserMemberDetail,
        props: true
    },
    {
        path: '/user/approval',
        name: 'UserApprovalList',
        component: UserApprovalList,
        props: true
    },
    {
        path: '/user/:userUuid/approval',
        name: 'UserApprovalDetail',
        component: UserApprovalDetail,
        props: true
    },
    {
        path: '/user/:userType',
        name: 'User',
        component: UserManagement,
        props: true
    },
    {
        path: '/user/:userType/:mode/:id?',
        name: 'UserDetail',
        component: UserDetail,
        props: true
    },
    {
        path: '/menu/:type',
        name: 'MenuManagement',
        component: MenuManagement,
        props: true
    },
    {
        path: "/support/notice",
        name: "NoticeManagement",
        component: NoticeManagement,
    },
    {
        path: "/support/notice/create", 
        name: "NoticeWrite",
        component: NoticeDetail,
        props: false, 
    },
    {
        path: "/support/notice/:uuid",
        name: "NoticeDetail",
        component: NoticeDetail,
        props: true,
    },
    {
        path: "/support/inquiry",
        name: "InquiryManagement",
        component: InquiryManagement,
    },
    {
        path: "/support/inquiry/:uuid",
        name: "InquiryDetail",
        component: InquiryDetail,
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    const mainStore = useMainStore();
    localStorage.setItem('currentPath', mainStore.currentPath);
    next()
});

export default router;
